import { inject } from '@angular/core'
import { CanActivateFn, Router, UrlTree } from '@angular/router'
import { TranslocoService } from '@jsverse/transloco'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { UserService } from '~core/services'
import { SnackbarService } from '~core/services/ui/snackbar.service'

export const maxSegmentGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const router = inject(Router)
  const snackbarService = inject(SnackbarService)
  const translateService = inject(TranslocoService)
  const userService = inject(UserService)

  return userService.canCreateSegment().pipe(
    map((canCreate) => {
      if (!canCreate) {
        snackbarService.warning(translateService.translate('alerts.segmentsLimitReached'))
        return false
      }

      // Limit not reached
      return true
    }),
    catchError((error) => {
      if (error.status === 503) {
        return router.navigate(['maintenance'])
      }
      return throwError(error)
    }),
  )
}
